import { LinearProgress, ThemeProvider, createTheme } from '@material-ui/core';
import React, { Fragment } from 'react';
/* Admin */
/* Officer */
/* Foreman */
/* 404 */
import { createSelector } from '@reduxjs/toolkit';
import CacheBuster from 'react-cache-buster';
import ReactGA from 'react-ga';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import packageInfo from '../package.json';
import './App.css';
import { useAppSelector } from './store/store';
/* Snackbars and Errors */
import { HttpError, HttpSuccess } from './components/snackbars';
/* Accounts and Auth */
import ForgotPassword from './components/accounts/forgot/ForgotPassword';
import NewPassword from './components/accounts/forgot/NewPassword';
import Signin from './components/accounts/signin/Signin';
import Signup from './components/accounts/signup/Signup';
import VerifyEmail from './components/accounts/verify/Verifyemail';
/* Index and Dashboard */
import { Chart, registerables } from 'chart.js';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Avatar from './assets/images/logo.png';
import IndexComponent from './components/Index';
import NotFound from './components/NotFound';
import AdminDashboard from './components/admin/Admin';
import AdminInvoice from './components/admin/Invoice';
import AdminInvoices from './components/admin/Invoices';
import AdminMerchants from './components/admin/Merchants';
import AdminClient from './components/admin/clients/Client';
import AdminClients from './components/admin/clients/Clients';
import AdminAddUser from './components/admin/officers/AddOfficer';
import AdminEditOfficer from './components/admin/officers/EditOfficer';
import AdminOfficers from './components/admin/officers/Officers';
import AdminReceipt from './components/admin/receipts/Receipt';
import AdminReceipts from './components/admin/receipts/Receipts';
import AdminVariables from './components/admin/variables/calculation';
import DashBoardPage from './components/dashboard/Dashboard';
import DashFAQs from './components/dashboard/FAQs';
import DashMessages from './components/dashboard/Messages';
import DashPricing from './components/dashboard/Pricing';
import TermsAndC from './components/dashboard/TermsAndC';
import Changepassword from './components/dashboard/changepassword/Changepassword';
import Editprofile from './components/dashboard/editprofile/Editprofile';
import Invoice from './components/dashboard/invoices/Invoice';
import Invoices from './components/dashboard/invoices/Invoices';
import Receipt from './components/dashboard/receipts/Receipt';
import Receipts from './components/dashboard/receipts/Receipts';
import EditRegisteredItem from './components/dashboard/registereditems/EditItem';
import DashEligibleItems from './components/dashboard/registereditems/EligibleItems';
import RegisteredItems from './components/dashboard/registereditems/Registereditems';
import PackageHistoryClient from './components/dashboard/registereditems/packageHistoryClient';
import OfficerNewPassword from './components/officer/NewPassword';
import OfficerDashboard from './components/officer/Officer';
import OfficerSuspended from './components/officer/OfficerSuspended';
import OfficerClient from './components/officer/localOfficer/Client';
import OfficerInvoice from './components/officer/localOfficer/Invoice';
import OfficerInvoices from './components/officer/localOfficer/Invoices';
import OfficerMessages from './components/officer/localOfficer/Messages';
import OfficerStats from './components/officer/localOfficer/Stats';
import OfficerArchived from './components/officer/localOfficer/archived';
import LocalOfficerDashboard from './components/officer/localOfficer/localOfficer';
import PackageHistory from './components/officer/localOfficer/packageHistory';
import OfficerReceipt from './components/officer/receipts/Receipt';
import OfficerReceipts from './components/officer/receipts/Receipts';
import { Routes } from './routes';
import AdminRoute from './routes/adminRoute';
import ClientRoute from './routes/clientRoute';
import GuestRoute from './routes/guestRoute';
import OfficerRoute from './routes/officerRoute';

ReactGA.initialize('UA-000000-01');
ReactGA.pageview(window.location.pathname + window.location.search);

const theme = createTheme();
Chart.register(...registerables);

const router = createBrowserRouter(
	createRoutesFromElements(
		<Fragment>
			{/* Index */}
			<Route path={Routes.Home} element={<IndexComponent />} />
			{/* Accounts */}
			<Route path={Routes.Signin} element={<GuestRoute component={<Signin />} />} />
			<Route path={Routes.Signup} element={<GuestRoute component={<Signup />} />} />
			<Route path={Routes.VerifyEmail} element={<GuestRoute component={<VerifyEmail />} />} />
			<Route path={Routes.Forgotpassword} element={<GuestRoute component={<ForgotPassword />} />} />
			<Route path={Routes.NewPassword} element={<GuestRoute component={<NewPassword />} />} />
			{/* Dashboard */}
			<Route path={Routes.Dashboard} element={<ClientRoute component={<DashBoardPage />} />} />
			<Route path={Routes.EditProfile} element={<ClientRoute component={<Editprofile />} />} />
			<Route path={Routes.ChangePassword} element={<ClientRoute component={<Changepassword />} />} />
			<Route path={Routes.Receipts} element={<ClientRoute component={<Receipts />} />} />
			<Route path={Routes.receipt} element={<ClientRoute component={<Receipt />} />} />
			<Route path={Routes.Invoices} element={<ClientRoute component={<Invoices />} />} />
			<Route path={Routes.Invoice} element={<ClientRoute component={<Invoice />} />} />
			<Route path={Routes.RegisteredItems} element={<ClientRoute component={<RegisteredItems />} />} />
			<Route path={Routes.EditRegisteredItems} element={<ClientRoute component={<EditRegisteredItem />} />} />
			<Route path={Routes.EligibleItems} element={<ClientRoute component={<DashEligibleItems />} />} />
			<Route path={Routes.Messages} element={<ClientRoute component={<DashMessages />} />} />
			<Route path={Routes.Pricing} element={<ClientRoute component={<DashPricing />} />} />
			<Route path={Routes.TermsAndConditions} element={<ClientRoute component={<TermsAndC />} />} />
			<Route path={Routes.FAQs} element={<ClientRoute component={<DashFAQs />} />} />
			<Route path={Routes.DashHistory} element={<ClientRoute component={<PackageHistoryClient />} />} />
			{/* Admin  */}
			<Route path={Routes.AdminDash} element={<AdminRoute component={<AdminDashboard />} />} />
			{/* <Route path={Routes.AdminSignIn} element={<AdminRoute component={<AdminSignIn />} />} /> */}
			<Route path={Routes.AdminOfficers} element={<AdminRoute component={<AdminOfficers />} />} />
			<Route path={Routes.AdminAddOfficers} element={<AdminRoute component={<AdminAddUser />} />} />
			<Route path={Routes.AdminEditOfficer} element={<AdminRoute component={<AdminEditOfficer />} />} />
			<Route path={Routes.AdminClients} element={<AdminRoute component={<AdminClients />} />} />
			<Route path={Routes.AdminClient} element={<AdminRoute component={<AdminClient />} />} />
			<Route path={Routes.AdminMerchants} element={<AdminRoute component={<AdminMerchants />} />} />
			<Route path={Routes.AdminInvoices} element={<AdminRoute component={<AdminInvoices />} />} />
			<Route path={Routes.AdminInvoice} element={<AdminRoute component={<AdminInvoice />} />} />
			<Route path={Routes.AdminReceipts} element={<AdminRoute component={<AdminReceipts />} />} />
			<Route path={Routes.AdminVariables} element={<AdminRoute component={<AdminVariables />} />} />
			<Route path={Routes.AdminReceipt} element={<AdminRoute component={<AdminReceipt />} />} />
			{/* Officer */}
			{/* <OfficerRoute path={Routes.OfficerSignin} component={OfficerSigninPage} /> */}
			<Route path={Routes.OfficerNewPassword} element={<OfficerRoute component={<OfficerNewPassword />} />} />
			<Route path={Routes.OfficerDash} element={<OfficerRoute component={<OfficerDashboard />} />} />
			<Route path={Routes.OfficerSuspended} element={<OfficerRoute component={<OfficerSuspended />} />} />
			<Route path={Routes.OfficerLocalOfficer} element={<OfficerRoute component={<LocalOfficerDashboard />} />} />
			<Route path={Routes.OfficerMessages} element={<OfficerRoute component={<OfficerMessages />} />} />
			<Route path={Routes.OfficerInvoices} element={<OfficerRoute component={<OfficerInvoices />} />} />
			<Route path={Routes.OfficerInvoice} element={<OfficerRoute component={<OfficerInvoice />} />} />
			<Route path={Routes.OfficerReceipts} element={<OfficerRoute component={<OfficerReceipts />} />} />
			<Route path={Routes.OfficerReceipt} element={<OfficerRoute component={<OfficerReceipt />} />} />
			<Route path={Routes.OfficerClients} element={<OfficerRoute component={<AdminClients />} />} />
			<Route path={Routes.OfficerClient} element={<OfficerRoute component={<OfficerClient />} />} />
			<Route path={Routes.OfficerArchived} element={<OfficerRoute component={<OfficerArchived />} />} />
			<Route path={Routes.PackageHistory} element={<OfficerRoute component={<PackageHistory />} />} />
			<Route path={Routes.OfficerStats} element={<OfficerRoute component={<OfficerStats />} />} />
			{/* Foreman 
		<Route path={Routes.Foreman} component={ForemanDashPage} exact />
		<Route path={Routes.ForemanEditOfficer} component={ForemanEditOfficerPage} exact /> **/}
			{/* !exact routes */}
			<Route errorElement={<NotFound />} />
		</Fragment>
	)
);

function AppDefault() {
	const selectAuth = createSelector([state => state.auth], auth => {
		return {
			auth,
		};
	});
	const { auth } = useAppSelector(selectAuth);

	const isProduction = process.env.NODE_ENV === 'production';
	const { errorMsg, successMsg } = auth;

	/// useEffect(() => {
	// 	const socket = new WebSocket('wss://ship-test-api.vitumob.com');

	// 	const id = localStorage.getItem('profileId');

	// 	socket.addEventListener('message', event => {
	// 		let invoice = JSON.parse(event.data);
	// 		if (invoice.type === 'new') {
	// 			if (invoice.client === id) {
	// 				window.dataLayer.push({
	// 					event: 'invoice_new',
	// 					amount: invoice.amount,
	// 					kg: invoice.weight,
	// 					id: invoice.id,
	// 					shipping_country: invoice.country,
	// 				});
	// 			}
	// 		}

	// 		if (invoice.type === 'paid') {
	// 			if (invoice.client === id) {
	// 				window.dataLayer.push({
	// 					event: 'invoice_paid',
	// 					amount: invoice.amount,
	// 					kg: invoice.weight,
	// 					id: invoice.id,
	// 					shipping_country: invoice.country,
	// 				});
	// 			}
	// 		}
	// 	});
	// }, []);
	return (
		<CacheBuster
			currentVersion={packageInfo.version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			loadingComponent={<p></p>} //If not pass, nothing appears at the time of new version check.
			metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
		>
			<ThemeProvider theme={theme}>
				<div className='app'>
					<div className='linear_progress'>
						<LinearProgress style={{ display: auth.fetchingData ? 'block' : 'none' }} />
					</div>
					<RouterProvider router={router} />
					{/* Error handler */}
					{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
					{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
					<FloatingWhatsApp
						avatar={Avatar}
						phoneNumber={+254780363433}
						accountName='Vitumob Company Limited'
						className='mb-10'
						chatboxHeight={300}
						style={{ marginBottom: '40px' }}
					/>
				</div>
			</ThemeProvider>
		</CacheBuster>
	);
}

export default AppDefault;
